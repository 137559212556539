<template>
	<div class="text">
		<div class="text-header">
			<img class="header-logo" :src="require('../../assets/icons/logo-half.svg')" />
			<div class="header-title">{{ t('TOS.title') }}</div>
		</div>
		<div class="text-body">
			<p>INSHOP.gg as an administrator of the Website adopts these Terms of Use that specifies User’s rights and obligations and constitute a legally binding agreement for both parties. These Terms of Use affect User’s rights and impose certain obligations while using the Website, so the User must read them carefully.</p>
			<h1>1. Terms and definition</h1>
			<p>
				1.1.
				<br />«Agency agreement» – Agency agreement, which concluded between the User and the inshop.gg on the conditions available at https://inshop.gg/agent/. Agency agreement is the integral part of the Terms of Use.
				<br />«Bid» – User’s offer for inshop.gg to conclude the Deal of the sale or purchase the Items.
				<br />«Deal» – Sale or purchase of Items under these Terms of Use.
				<br />«Items» – Virtual goods for use within the computer games listed in the section of “Inventory” of User’s account on the website: https://steamcommunity.com.
				<br />«Personal account» - Account maintained by the User for the purposes of trading through inshop.gg.
				<br />«Services» – Services provided by inshop.gg through the Website.
				<br />«Terms of Use» – An agreement between the User and inshop.gg available at https://inshop.gg/tos/
				<br />«User» – An individual who uses the Website under Terms of Use.
			</p>
			<p>
				1.2.
				These Terms of Use shall be governed by and construed in accordance with the laws of inshop.gg domicile, unless otherwise expressly provided. All disputes and controversies arising out of or in connection with this website and these Terms of Use shall be submitted to applicable courts at the venue of inshop.gg registered office.
			</p>
			<h1>2. Use of the Website</h1>
			<p>
				2.1.
				Upon these Terms of Use inshop.gg hereby grants the User a non-exclusive, non-transferable limited license to use the Website in accordance with the terms and conditions stated below. All rights not expressly granted herein are fully reserved by inshop.gg, its advertisers and licensors.
			</p>
			<p>
				2.2.
				inshop.gg hereby also grants the User permission to use content of the Website, provided that User (1) uses it solely for personal, noncommercial use (other than trading Items featured on the Website); (2) does not modify or sell content of the Website; and (3) does not reproduce, display, publicly perform, distribute, or otherwise use the content of the Website for any public or commercial purpose, including the use of the content on any other Website.
			</p>
			<p>
				2.3.
				Through its Services, inshop.gg assists Users to find, compare, purchase, and sell Items. inshop.gg receives a fee from Users for connecting them with each other; however, inshop.gg does not provide or otherwise sell Items featured on the Website.
			</p>
			<h1>3. Trading process</h1>
			<p>
				3.1.
				inshop.gg allows Users to submit Bids to purchase/sell Items through the Website.
			</p>
			<p>
				3.2.
				The Deal is executed by the inshop.gg on conditions specified in the Agency Agreement.
			</p>
			<p>
				3.3.
				The User is entitled to cancel his/her Bid at any time before such Bid has been processed for the purposes of accomplishing the Deal.
			</p>
			<p>
				3.4.
				In case of purchase of Items, the Bid shall contain detailed specification of certain Items. inshop.gg executes the Deal and notifies the User in the Personal account. Upon receiving notification, the User is entitled to accept the Deal and receive Items.
			</p>
			<p>
				3.5.
				In case of sale the Items, the Bid shall contain detailed specification of the certain Items and its selling price. All other terms of the Deal are determined at the sole discretion of inshop.gg.
			</p>
			<p>
				3.6.
				Based on Bids submitted by Users inshop.gg places information under para 3.5 of Terms of Use on the Website.
			</p>
			<p>
				3.7.
				Any User has the opportunity to make Deals through the Website and respond to Bids placed by other Users under paras 3.4.-3.5. inshop.gg executes the Deal with the first responded User.
			</p>
			<p>
				3.8.
				inshop.gg effect the Deal and notify the User in the Personal account in accordance with the Bid. Upon receiving notification, the User is entitled to pass the Items to inshop.gg for further compensatory transfer the Items for the User who is first responded on the post. After the transfer of the User’s Items, inshop.gg receive funds from the Deal and transfer it to the User’s account.
			</p>
			<h1>4. Fees</h1>
			<p>
				4.1.
				Upon these Terms of Use, the User agrees to pay and is responsible for all applicable charges and fees for Services. Fees of inshop.gg amounts for 10% (ten percent) of the Deal.
			</p>
			<p>
				4.2.
				All fees owed by the User to the third parties (including the Financial institution) are not covered by this Terms of Use. All fees owed by inshop.gg to the third parties (including the Financial institution) related to tranferring funds to Personal account are covered by User.
			</p>
			<p>
				4.3.
				Fees that are specified in para 4.1 of Terms of Use, may be reduced by inshop.gg out of funds received to execute the Deal.
			</p>
			<p>
				4.4.
				inshop.gg provides for additional fees under the Agency agreement.
			</p>
			<p>
				4.5.
				inshop.gg withholds its fees specified in para 4.4 of Terms of Use on conditions specified in the Agency agreement.
			</p>
			<p>
				4.5.1
				All Deals concerning the acquisition of the Inventory are not subject to cancellation. Principal's funds used for concluding Deals concerning the acquisition of the Inventory are not subject to refund
			</p>
			<p>
				4.6.
				The transfer of funds to inshop.gg could be processed via:
			</p>
			<p>
				4.6.1.
				Bank transfer to inshop.gg account or by payment intermediaries which have the contracts with inshop.gg;
			</p>
			<p>
				4.6.2.
				Transfer of electronic money to the account of inshop.gg;
			</p>
			<p>
				4.6.3.
				Transfer of other monetary units to inshop.gg account of e-commerce payment services.
			</p>
			<h1>5. Disclaimer</h1>
			<p>
				5.1
				inshop.gg expressly disclaims any warranty for work of Website, including duties of lack of negligence and lack of workmanlike effort.
			</p>
			<p>
				5.2
				Everything on the Website shall be regarded “as is” without any express or implied representations or warranties of non-infringement, merchantability of the Website and its fitness for a particular purpose.
			</p>
			<p>
				5.3
				inshop.gg may remove or suspend at any time and without any prior warning the services provided to Users under these Terms of Use.
			</p>
			<h1>6. Liability</h1>
			<p>
				6.1.
				inshop.gg shall not be responsible or liable for any direct, indirect, incidental, consequential, special, exemplary, punitive or other losses or damages (including, but not limited to, damages for loss of profits, loss of business, use, data or other intangible damages), under any contract, tort (including, without limitation, negligence and strict liability) or other legal theory, howsoever caused, arising out of or relating in any way to the Website its contents or any information on the Website, or any errors or omissions therein.
			</p>
			<p>
				6.2.
				inshop.gg shall not be liable for:
			</p>
			<p>
				6.2.1.
				hardware, software or internet connection is not functioning properly;
			</p>
			<p>
				6.2.2.
				any suspension or refusal to accept payments which inshop.gg reasonably believes to be made fraudulently or without proper authorization;
			</p>
			<p>
				6.2.3.
				unforeseen circumstances preventing the proper performance despite any reasonable precautions taken by inshop.gg (power outages, fire, flood, theft, equipment breakdowns, hacking attacks, internal mechanical or systems failures as well as downtimes of the Website).
			</p>
			<h1>7. Links to Other Websites</h1>
			<p>
				7.1.
				The Website may contain links to third-party Internet websites, resources, advertisers, services, special offers, or other events or activities that are not owned or administered by inshop.gg. inshop.gg is not responsible for the contents of such third-party websites, materials, information or services. The access and use of such websites, materials, information or services are at User’s own risk
			</p>
			<h1>8. Changes, Duration and Termination</h1>
			<p>
				8.1.
				These Terms of Use are subject to changes, amendments and modifications. By continuing to use the Website, User is considered to be bound by the amended version of these Terms of Use. In the event of change of Terms of Use, User will be sent a notice to user’s e-mail address about it. If any changes to Terms of Use are not acceptable to a User, the User’s only remedy is to cancel his/her account and stop using the Website.
			</p>
			<p>
				8.2.
				The services and the information included in the Website have an indefinite duration. inshop.gg may terminate or suspend the service or the information, posted in the Website totally or partially at any time and without prior notice.
			</p>
			<h1>9. Dispute Resolution</h1>
			<p>
				9.1.
				If a dispute, disagreement or claim arises out of Terms of Use or related to its execution, termination or rescission of the Terms of Use both parties agree to try in good faith to settle any dispute, disagreement or claim through negotiation. If the claiming party is inshop.gg, it sends a message with its claim to the User. The message in question shall contain the essentials of the claim and proofs supporting the claim.
			</p>
			<p>
				9.2.
				The party which has received the claim shall reply to the claiming party within 5 (five) working days upon the receipt of the claim.
			</p>
			<p>
				9.3.
				In absence of reply to the claim within 10 (ten) working days since the sending date, or if the Parties have failed to resolve the dispute, the claim shall be lodged with the appropriate court.
			</p>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	setup() {
		const { t } = useI18n()

		return {
			t
		}
	}
})
</script>
<style scoped>
@import url("../../assets/styles/text.css");
</style>
